import { template as template_30e93554600d4ed8a8f7ce11fdcb5f12 } from "@ember/template-compiler";
const FKControlMenuContainer = template_30e93554600d4ed8a8f7ce11fdcb5f12(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
