import { template as template_178fa4cd25514779aadf6bd4e9fef3b3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_178fa4cd25514779aadf6bd4e9fef3b3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
