import { template as template_a4731c6233ee4b9e87e7177d77614092 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a4731c6233ee4b9e87e7177d77614092(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
